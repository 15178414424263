<template>
  <div class="footer-label-wrapper">
    <div class="footer-label-content">
      <div v-for="(item, index) in footerLabels" :key="`ft-${getLocaleString(item.name)}-${index}`" class="footer-label-item">
        <div class="footer-label-item--icon" :style="{'background-color': item.bgColor, 'fill': item.fillColor}">
          <NuxtImg class="footer-label-item--icon-img" :src="item.icon" />
        </div>

        <div class="footer-label-item--wrapper">
          <div class="footer-label-item--title">
            {{ getLocaleString(item.name) }}
          </div>

          <div class="footer-label-item--subtitle" v-if="item?.description && getLocaleString(item.description)">
            {{ getLocaleString(item.description) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { footerLabels } = storeToRefs(storeConfig)
</script>

<style lang="scss">
.footer-label-wrapper {
  @apply bg-white py-16 px-2 lg:px-4 z-0 text-primary;
}

.footer-label-content {
  @apply max-w-screen-2xl lg:mx-auto sm:flex sm:divide-x sm:divide-y-0 divide-y divide-gray-100 items-center justify-center md:space-y-0 space-y-4 sm:space-y-0 sm:space-x-6 flex-wrap gap-5;
}

.footer-label-item--wrapper {

}

.footer-label-item {
  @apply flex flex-wrap items-center pt-4 sm:pt-0 sm:pl-6 justify-start sm:justify-center space-x-6;
}

.footer-label-item--icon {
  @apply inline-flex items-center justify-center h-16 w-16 rounded-full flex-shrink-0;
}

.footer-label-item--icon-img {
  @apply h-12 w-12 flex items-center justify-center;
}

.footer-label-item--icon-svg {
  @apply h-12 w-12 flex items-center justify-center;
}

.footer-label-item--title {
  @apply text-left text-base font-bold flex-grow-0;
}

.footer-label-item--subtitle {
  @apply text-left text-base text-gray-500;
}
</style>